import { MinimalTopNavigationBarClient, TopPromoBanner } from "@/components/Header";
import { MINISTORE } from "@/lib/const";
import type { Payload } from "@local/payload-client/src/types";
interface Props {
  initialData: Payload.Mini | null;
  noTopBar?: boolean;
  promoBannerHeigth?: number;
}
export const FakeHeader = ({
  initialData,
  noTopBar,
  promoBannerHeigth,
  onClick
}: {
  onClick?: () => void;
} & Props) => {
  return <header data-sentry-component="FakeHeader" data-sentry-source-file="FakeHeader.tsx">
      <div className="relative">
        {!noTopBar && <TopPromoBanner text={initialData?.topbar} />}
        <MinimalTopNavigationBarClient initialData={initialData} store={MINISTORE} promoBannerHeigth={promoBannerHeigth ?? 32} heightClasses="h-[70px] lg:h-[60px]" onClick={onClick} data-sentry-element="MinimalTopNavigationBarClient" data-sentry-source-file="FakeHeader.tsx" />
      </div>
    </header>;
};