import { create } from "zustand"

interface SearchState {
  isOpen: boolean
  toggleOpen: (target?: boolean) => void
}

export const useSearchLayout = create<SearchState>((set) => ({
  isOpen: false,
  toggleOpen: (target) => {
    set((state) => ({ isOpen: target !== undefined ? target : !state.isOpen }))
  },
}))
