"use client";

import { useSearchParams, usePathname } from "next/navigation";
import ToastServer from ".";
import Cookies from "js-cookie";
import { Suspense } from "react";
const ToastWrapper = () => {
  const params = useSearchParams();
  const pathname = usePathname();
  const coupon = params?.get("coupon");
  const doubleOptIn = params?.get("double-opt-in") && !pathname?.includes("checkout");
  const cookieCoupon = Cookies.get("coupon-toast-viewed");
  const optinCoupon = Cookies.get("optin-toast-viewed");
  const showCouponToast = !cookieCoupon && !!coupon;
  const showOptinToast = !optinCoupon && !!doubleOptIn;
  return <Suspense data-sentry-element="Suspense" data-sentry-component="ToastWrapper" data-sentry-source-file="ToastWrapper.tsx">
      {showCouponToast && <ToastServer content="Your discount has been automatically applied to your shopping cart." />}
      {showOptinToast && <ToastServer content="THANK YOU for your confirmation." />}
    </Suspense>;
};
export default ToastWrapper;