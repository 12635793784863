import { classNames } from "@local/utils";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { CloseIcon } from "../ui/icons";
import Cookies from "js-cookie";
const ToastServer = ({
  content
}: {
  content: string;
}) => {
  const [isAnimating, setIsAnimating] = useState<"close" | "open" | null>(null);
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating("open");
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const closeToast = () => {
    setIsAnimating("close");
    const timeout = setTimeout(() => {
      setIsOpen(false);
      setIsAnimating(null);
      Cookies.set("coupon-toast-viewed", "true", {
        expires: 1,
        secure: false
      });
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  };
  return isOpen ? <div className={classNames("w-full fixed top-0 right-full z-[999999]", isAnimating === "open" ? styles.ToastOpen : isAnimating === "close" ? styles.ToastClose : "", "-right-[32rem]")} data-sentry-component="ToastServer" data-sentry-source-file="index.tsx">
      <div className="relative">
        <div className={classNames("absolute py-6 pl-10 pr-16 m-4 bg-white top-0 sm:w-96 rounded-md shadow-xl text-lg md:text-base right-0", styles.Toast)} id="toaster">
          {content}
          <button className={classNames("absolute top-0 right-0 text-xs opacity-40 p-3", styles.CloseButton)} onClick={() => closeToast()}>
            <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
            <span className={styles.CloseButtonA11y}>Close</span>
          </button>
        </div>
      </div>
    </div> : null;
};
export default ToastServer;