"use client";

import { useEffect, useState, type Dispatch, type SetStateAction, type MutableRefObject, useCallback } from "react";
import { ContentBlockServer } from "../../ContentBlock";
import { classNames } from "@local/utils";
import { CloseIcon, SearchIcon } from "@/components/ui/icons";
import { useSearchLayout } from "./useSearchLayout";
import useIsMobile from "@/utils/useIsMobile";
import type { Payload } from "@local/payload-client/src/types";
import { FakeHeader } from "@/components/FakeHeader";
import { useInstantSearch, useSearchBox } from "react-instantsearch";
import { SearchResultPlaceholder } from "./Placeholder";
import { CustomHits } from "../Hits";
import type { BaseHit } from "instantsearch.js";
import styles from "./style.module.css";
import { useTracking } from "@local/tracking";
interface Props {
  initialData: Payload.Mini | null;
  placeHolderSearch?: boolean;
}
interface InnerSearchBoxProps {
  setInputValue: Dispatch<SetStateAction<string | undefined>>;
  setLastInput: Dispatch<SetStateAction<string | undefined>>;
  setFirstLoad: Dispatch<SetStateAction<boolean>>;
  inputValue: string | undefined;
}
export interface SearchInputRef {
  searchInputRef: MutableRefObject<HTMLInputElement | null>;
}
const DEBOUNCE_TIME = 800;

// Gambiarra for iPhone open keyboard on focus... thanks Apple
export function openIosKeyboard() {
  const input = document.createElement("input");
  input.setAttribute("type", "text");
  input.setAttribute("style", "position: fixed; top: -100px; left: -100px; font-size:16px;");
  document.body.appendChild(input);
  input.focus();
  setTimeout(() => {
    document.body.removeChild(input);
  }, 3000);
}
export const SearchButton = ({
  searchInputRef
}: SearchInputRef) => {
  const {
    toggleOpen: toggleSearch
  } = useSearchLayout();
  return <>
      <button className="z-20 h-full pl-6 pr-4 -mr-5 text-lg md:mr-0" aria-label="Open search" onClick={e => {
      e.preventDefault();
      openIosKeyboard();
      toggleSearch(true);
      const timeout = setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 300);
      return () => {
        clearTimeout(timeout);
      };
    }}>
        <SearchIcon className="" data-sentry-element="SearchIcon" data-sentry-source-file="index.tsx" />
      </button>
    </>;
};
const InnerSearchBox = ({
  setInputValue,
  inputValue,
  setLastInput,
  setFirstLoad,
  searchInputRef
}: InnerSearchBoxProps & SearchInputRef) => {
  const {
    refine
  } = useSearchBox();
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>();
  const {
    trackActionBuffered
  } = useTracking();
  function setQuery(newQuery: string) {
    if (newQuery.length > 2) {
      if (timerId) {
        clearTimeout(timerId);
      }
      setTimerId(setTimeout(() => {
        refine(newQuery);
        setLastInput(newQuery);
        setFirstLoad(false);
        trackActionBuffered("search", {
          search_term: newQuery
        });
      }, DEBOUNCE_TIME));
    }
  }
  return <div className={classNames("w-full", styles.inputContainer)} data-sentry-component="InnerSearchBox" data-sentry-source-file="index.tsx">
      <form action="" role="search" noValidate onSubmit={event => {
      event.preventDefault();
      event.stopPropagation();
      if (searchInputRef.current) {
        searchInputRef.current.blur();
      }
    }} onReset={event => {
      event.preventDefault();
      event.stopPropagation();
      setQuery("");
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }}>
        <input autoCapitalize="off" autoComplete="off" autoCorrect="off" className="w-[80%] pl-8 text-xl md:text-2xl text-white bg-transparent border-t-0 border-b-2 border-x-0 border-zinc-500 focus:border-zinc-500 focus-outline-none focus:ring-0 focus:ring-transparent focus:placeholder-transparent placeholder-zinc-500" onChange={event => {
        setInputValue(event.currentTarget.value);
        setQuery(event.currentTarget.value);
      }} autoFocus onFocus={e => e.currentTarget.select()} placeholder="Start typing to search" ref={element => {
        // save element on `inputElement.current`
        // so that we can access it later
        searchInputRef.current = element;
      }} spellCheck={false} type="search" value={inputValue || ""} aria-required aria-label="Search" />
      </form>
    </div>;
};
export const SearchContainer = ({
  initialData,
  placeHolderSearch,
  searchInputRef
}: Props & SearchInputRef) => {
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [lastInput, setLastInput] = useState<string | undefined>();
  const [changeOpacity, setChangeOpacity] = useState<boolean>(false);
  const [currentHits, setCurrentHits] = useState<BaseHit[]>();
  const {
    toggleOpen: toggleSearch,
    isOpen: open
  } = useSearchLayout();
  const isMobile = useIsMobile();
  const [firstLoad, setFirstLoad] = useState<boolean>(placeHolderSearch !== undefined ? !placeHolderSearch : true);
  //if changing firstLoad to false, remember to also change on ./Wrapper.tsx

  const {
    status,
    error,
    results: {
      hits
    }
  } = useInstantSearch();
  const closeSearch = useCallback(() => {
    setChangeOpacity(false);
    const timeout = setTimeout(() => {
      setInputValue("");
      toggleSearch(false);
      if (document) {
        document.body.style.overflow = "auto";
      }
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [toggleSearch]);
  useEffect(() => {
    setCurrentHits(hits);
  }, [hits, setCurrentHits]);
  const escFunction = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") {
      closeSearch();
      document.body.style.overflow = "auto";
    }
  }, [closeSearch]);
  useEffect(() => {
    if (document && open) {
      document.addEventListener("keydown", escFunction, false);
      document.body.style.overflow = "hidden";
      const timeout = setTimeout(() => {
        setChangeOpacity(true);
      }, 100);
      return () => {
        document.removeEventListener("keydown", escFunction, false);
        clearTimeout(timeout);
      };
    }
  }, [escFunction, open]);
  if (!open) {
    return;
  }
  const Empty = () => <div className="py-24 text-center bg-gradient-to-b from-transparent to-yellow-50 rounded-xl" data-sentry-component="Empty" data-sentry-source-file="index.tsx">
      <h2 className="text-3xl font-bold">
        {lastInput ? `No results found for \"${lastInput}\"` : error ? `No results: ${error}` : "No results found"}
      </h2>
      <p className="text-[120px]">🏜️</p>
      <p>Try searching for something else...</p>
    </div>;
  return <div className={classNames("fixed z-[900] transition-opacity ease-in duration-200", changeOpacity ? "opacity-100" : "opacity-0")} data-sentry-component="SearchContainer" data-sentry-source-file="index.tsx">
      <div className="fixed top-0 left-0 z-[990] w-[100vw] h-[100vh] overflow-scroll">
        <FakeHeader initialData={initialData} onClick={() => closeSearch()} data-sentry-element="FakeHeader" data-sentry-source-file="index.tsx" />
        <ContentBlockServer fullWidthBgColor="bg-neutral-700 z-[999]" data-sentry-element="ContentBlockServer" data-sentry-source-file="index.tsx">
          <div className="px-6 pt-6 pb-8 text-lg md:py-10 md:px-8 lg:px-12 md:text-2xl">
            <div className="relative z-[999] flex items-center w-full">
              <SearchIcon className={classNames("absolute mb-[3px]", inputValue ? "text-yellow-500" : "text-zinc-500")} data-sentry-element="SearchIcon" data-sentry-source-file="index.tsx" />
              <button className="absolute right-0 p-4 pr-0 text-base md:text-xl text-zinc-500" onClick={e => {
              e.preventDefault();
              closeSearch();
            }} role="button">
                <span className="sr-only">close</span>
                <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
              </button>

              <InnerSearchBox inputValue={inputValue} setInputValue={setInputValue} setLastInput={setLastInput} setFirstLoad={setFirstLoad} searchInputRef={searchInputRef} data-sentry-element="InnerSearchBox" data-sentry-source-file="index.tsx" />

              <div className="bg-yellow-500 h-[2px] absolute bottom-0 max-w-full" style={{
              // weird adjustment to make the underline fit the text.
              // kerning of char "0" results in monospace.
              width: inputValue?.length ? `${inputValue.length + (isMobile ? 3.6 : 3 - inputValue.length / 4)}ch` : 0
            }} />
            </div>
          </div>
        </ContentBlockServer>

        {/* {(inputValue && inputValue?.length > 2) || lastInput ? ( */}
        {!firstLoad && <div className="bg-white z-[998]" style={{
        backdropFilter: "blur(5px)"
      }}>
            {status === "error" ? <Empty /> : status === "loading" ? <SearchResultPlaceholder /> : status === "stalled" ? <SearchResultPlaceholder /> : currentHits && currentHits.length <= 0 ? <Empty /> : <CustomHits />}
          </div>}
        {/* ) : null} */}
      </div>

      <div className="absolute top-0 left-0 w-[100vw] h-[101vh] bg-opacity-85 bg-black z-[99]" style={{
      backdropFilter: "blur(3px)"
    }} onClick={e => {
      e.stopPropagation();
      closeSearch();
    }} />
    </div>;
};
export default SearchContainer;