"use client";

import { cva } from "class-variance-authority";
import { ContentBlockServer } from "../ContentBlock";
import { HeaderMenuDesktopServer } from "../HeaderMenu";
import { HeaderMenuMobileButtonClient } from "../HeaderMenu/MenuMobile";
import { LogoNoLinkServer, LogoServer } from "../Logo";
import type { StoreType } from "@/lib/types";
import type { Payload } from "@local/payload-client/src/types";
import { type MutableRefObject, Suspense, useRef } from "react";
// import { isBrowser } from "@local/utils/src/isBrowser"
import { classNames } from "@local/utils/src/classNames";
import dynamic from "next/dynamic";
import { ShoppingCartLoaderClient } from "../Loaders/Cart";
// import useScroll from "@/utils/useScroll"
import ScrollHandler from "./ScrolHandler";
import styles from "./styles.module.css";
import { IS_BAERSKIN, IS_COZISTORE, IS_HAM } from "@/lib/const";
import DynamicFaviconAndTitleClient from "@local/ui/src/DynamicFaviconAndTitle";
import { SearchButton } from "../Search/SearchContainer";
import ToastWrapper from "../Toast/ToastWrapper";
const ShoppingCartClient = dynamic(() => import("../Cart/CartButton").then(mod => mod.ShoppingCartClient), {
  ssr: false,
  loading: () => <ShoppingCartLoaderClient />
});
const SearchWrapper = dynamic(() => import("../Search/Wrapper"), {
  ssr: false
});
interface Props {
  heightClasses: string;
  initialData: Payload.Mini | null;
  noCart?: boolean;
  noLogoLink?: boolean;
  noNavigation?: boolean;
  promoBannerHeigth?: number;
  sticky?: boolean;
  store: StoreType;
}
const fullWidthBgColor = cva("", {
  variants: {
    store: {
      cozistore: "bg-white",
      baerskintactical: "bg-gray-900",
      hamstore: "bg-[#AFE7F0]"
    }
  },
  defaultVariants: {
    store: "cozistore"
  }
});
export const TopNavigationBarClient = ({
  initialData,
  store,
  promoBannerHeigth,
  heightClasses,
  noNavigation,
  noCart,
  noLogoLink
}: Props) => {
  const navBarStyle = cva(classNames("flex-row flex items-center justify-between lg:px-16 relative", heightClasses), {
    variants: {
      store: {
        cozistore: "text-black",
        baerskintactical: "text-white",
        hamstore: "text-sky-700"
      }
    },
    defaultVariants: {
      store: "cozistore"
    }
  });
  const logoStyles = cva("lg:-ml-3", {
    variants: {
      noNavigation: {
        true: "-ml-4",
        false: ""
      }
    }
  });
  const ref = useRef<HTMLDivElement>(null);
  const searchInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  return <>
      <DynamicFaviconAndTitleClient favicon={initialData?.favicon?.url as string} data-sentry-element="DynamicFaviconAndTitleClient" data-sentry-source-file="TopNavigationBar.tsx" />
      <Suspense data-sentry-element="Suspense" data-sentry-source-file="TopNavigationBar.tsx">
        <ToastWrapper data-sentry-element="ToastWrapper" data-sentry-source-file="TopNavigationBar.tsx" />
      </Suspense>

      <div className={classNames("relative z-[200]", heightClasses)} role="complementary">
        <div className="absolute w-full" id="sticky-topbar-hardcoded" ref={ref}>
          <ContentBlockServer fullWidthBgColor={fullWidthBgColor({
          store
        })} className={navBarStyle({
          store
        })} data-sentry-element="ContentBlockServer" data-sentry-source-file="TopNavigationBar.tsx">
            {!noNavigation && <HeaderMenuMobileButtonClient />}
            <div className="relative flex flex-row items-center justify-center w-full h-full gap-6 lg:justify-start lg:items-start">
              {noLogoLink ? <LogoNoLinkServer image={IS_COZISTORE || IS_HAM ? initialData?.logo : initialData?.logoDark} storeName={initialData?.name ?? "Baerskin Tactical"} className={logoStyles({
              noNavigation
            })} /> : <LogoServer image={IS_COZISTORE || IS_HAM ? initialData?.logo : initialData?.logoDark} storeName={initialData?.name ?? "Baerskin Tactical"} className={logoStyles({
              noNavigation
            })} />}
              {!noNavigation && <HeaderMenuDesktopServer initialData={initialData} />}
            </div>
            {IS_BAERSKIN && !noNavigation && <SearchButton searchInputRef={searchInputRef} />
          // <SearchWrapper />
          }
            {!noCart && <ShoppingCartClient />}
          </ContentBlockServer>
        </div>
      </div>
      {IS_BAERSKIN && <SearchWrapper initialData={initialData} searchInputRef={searchInputRef} />}
      <ScrollHandler className={styles.fixednavbar} elementRef={ref} promoBannerHeigth={promoBannerHeigth} data-sentry-element="ScrollHandler" data-sentry-source-file="TopNavigationBar.tsx" />
    </>;
};
export const MinimalTopNavigationBarClient = ({
  initialData,
  store,
  promoBannerHeigth,
  heightClasses,
  noNavigation,
  onClick
}: {
  onClick?: () => void;
} & Props) => {
  const navBarStyle = cva(classNames("flex-row flex items-center justify-between lg:px-16 relative", heightClasses), {
    variants: {
      store: {
        cozistore: "text-black",
        baerskintactical: "text-white",
        hamstore: "text-sky-700"
      }
    },
    defaultVariants: {
      store: "cozistore"
    }
  });
  const logoStyles = cva("lg:-ml-3", {
    variants: {
      noNavigation: {
        true: "-ml-4",
        false: ""
      }
    }
  });
  const ref = useRef<HTMLDivElement>(null);
  return <div onClick={e => {
    e.preventDefault();
    onClick && onClick();
  }} data-sentry-component="MinimalTopNavigationBarClient" data-sentry-source-file="TopNavigationBar.tsx">
      <div className={classNames("relative z-[200]", heightClasses)} role="complementary">
        <div className="absolute w-full" id="sticky-topbar-hardcoded" ref={ref}>
          <ContentBlockServer fullWidthBgColor={fullWidthBgColor({
          store
        })} className={navBarStyle({
          store
        })} data-sentry-element="ContentBlockServer" data-sentry-source-file="TopNavigationBar.tsx">
            <div className="relative flex flex-row items-center justify-center w-full h-full gap-6 lg:justify-start lg:items-start">
              <LogoNoLinkServer image={IS_COZISTORE || IS_HAM ? initialData?.logo : initialData?.logoDark} storeName={initialData?.name ?? "Baerskin Tactical"} className={logoStyles({
              noNavigation
            })} data-sentry-element="LogoNoLinkServer" data-sentry-source-file="TopNavigationBar.tsx" />
            </div>
          </ContentBlockServer>
        </div>
      </div>
      <ScrollHandler className={styles.fixednavbar} elementRef={ref} promoBannerHeigth={promoBannerHeigth} data-sentry-element="ScrollHandler" data-sentry-source-file="TopNavigationBar.tsx" />
    </div>;
};