"use client";

import { classNames } from "@local/utils/src/classNames";
import { BurgerMenuMobile, CloseIcon } from "../ui/icons";
import { useMenuLayout } from "./useMenuLayout";
import type { Payload } from "@local/payload-client/src/types";
import { Fragment, useEffect } from "react";
import Link from "next/link";
import { cva } from "class-variance-authority";
import { MINISTORE } from "@/lib/const";
import { isBrowser } from "@local/utils";
import { usePathname } from "next/navigation";
interface Props {
  navigationMain?: Payload.Mini["navigationMain"];
}
interface MenuItemProps {
  item: Payload.Mini["navigationMain"][0];
  setOpen: (target?: boolean | undefined) => void;
}
interface SubMenuItemProps {
  item: Payload.Mini["navigationMain"][0];
  setOpen: (target?: boolean | undefined) => void;
  index: number;
}
export const HeaderMenuMobileButtonClient = () => {
  const {
    toggleOpen: toggleMenu,
    isOpen: open
  } = useMenuLayout();
  const pathname = usePathname();
  useEffect(() => {
    toggleMenu(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // layout and page files can't be client components, so we do this
  useEffect(() => {
    if (isBrowser()) {
      if (open) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  }, [open]);
  return <button className="visible h-full p-1 -ml-1 sm:ml-1 lg:invisible lg:hidden aspect-square" onClick={() => toggleMenu(true)} aria-label="Toggle menu" data-sentry-component="HeaderMenuMobileButtonClient" data-sentry-source-file="MenuMobile.tsx">
      <div className="flex justify-center text-sm md:text-xs">
        <BurgerMenuMobile data-sentry-element="BurgerMenuMobile" data-sentry-source-file="MenuMobile.tsx" />
      </div>
    </button>;
};
const MenuSubItemMobile = ({
  item,
  index
}: SubMenuItemProps) => {
  const menuStyles = cva("flex flex-row justify-between font-medium hover:opacity-75", {
    variants: {
      store: {
        cozistore: "text-black",
        hamstore: "text-sky-700",
        baerskintactical: "text-zinc-100"
      },
      defaultVariants: {
        store: "baerskintactical"
      }
    }
  });
  const subMenuStyles = cva("flex flex-row justify-between font-medium hover:opacity-75", {
    variants: {
      store: {
        cozistore: "text-zinc-300",
        hamstore: "text-sky-700",
        baerskintactical: "text-zinc-400"
      },
      defaultVariants: {
        store: "baerskintactical"
      }
    }
  });
  if (item.subitems?.length === 0) {
    return <Link id={`mobile-featured-heading-${item.id}-${index}`} className={menuStyles({
      store: MINISTORE
    })} href={item.url ? item.url : `/category/${item.category?.slug}`} prefetch={false}>
        {item.name}
      </Link>;
  }
  return <Fragment>
      <Link href={item.url ? item.url : `/category/${item.category?.slug}`} id={`mobile-featured-heading-${item.id}-${index}`} className={classNames("flex flex-row justify-between font-medium hover:opacity-75", menuStyles({
      store: MINISTORE
    }))} prefetch={false} data-sentry-element="Link" data-sentry-source-file="MenuMobile.tsx">
        <span id={`mobile-list-heading-${item.id}`}>{item.name}</span>
      </Link>
      <ul role="list" aria-labelledby={`mobile-list-heading-${item.id}`} className="space-y-2">
        {item.subitems?.map((subitem, i) => <li key={subitem.id} className="flex">
            <Link id={`menu-item-mobile-client-${i}`} href={subitem.url ? subitem.url : `/category/${subitem.category?.slug}`} className={subMenuStyles({
          store: MINISTORE
        })} prefetch={false}>
              {subitem.name}
            </Link>
          </li>)}
      </ul>
    </Fragment>;
};
const MenuItemMobile = ({
  item,
  setOpen
}: MenuItemProps) => {
  return <div className="px-4 pt-10 space-y-4" data-sentry-component="MenuItemMobile" data-sentry-source-file="MenuMobile.tsx">
      <div className="grid items-start grid-cols-1 gap-x-4 gap-y-4">
        <div className="grid grid-cols-1 gap-4">
          <MenuSubItemMobile index={0} item={item} setOpen={setOpen} data-sentry-element="MenuSubItemMobile" data-sentry-source-file="MenuMobile.tsx" />
          {/* item.subitems?.map((subitem, j) => (
            <MenuSubItemMobile
              index={j}
              isDark={isDark}
              item={subitem}
              key={`mobile-featured-heading-${subitem.id}-${j}`}
            />
           )) */}
        </div>
      </div>
    </div>;
};
export const HeaderMenuMobileClient = ({
  navigationMain
}: Props) => {
  const {
    toggleOpen: toggleMenu,
    isOpen: open
  } = useMenuLayout();
  const menuStyles = cva("absolute left-0 flex w-full h-full max-w-xs md:max-w-sm lg:max-w-md flex-col overflow-y-auto pb-12 shadow-xl", {
    variants: {
      store: {
        cozistore: "bg-white text-gray-900",
        hamstore: "bg-[#AFE7F0] text-sky-700",
        baerskintactical: "bg-gray-900 text-white"
      },
      defaultVariants: {
        store: "baerskintactical"
      }
    }
  });
  return <div className={classNames("relative z-[240] xl:hidden", open ? "opacity-100" : "opacity-0")} style={{
    transition: "opacity linear 300ms"
  }} data-sentry-component="HeaderMenuMobileClient" data-sentry-source-file="MenuMobile.tsx">
      <div className={classNames("fixed inset-0 z-50 flex", open ? "visible block" : "invisible hidden", "bg-gray-600 bg-opacity-50 w-full backdrop-blur-sm")} onClick={e => e.preventDefault()} />
      <div className={classNames("fixed inset-0 z-50 flex", open ? "translate-x-0" : "-translate-x-full")} style={{
      transition: "transform ease-in-out 300ms"
    }}>
        <div className={menuStyles({
        store: MINISTORE
      })}>
          <div className="flex px-4 pt-5 pb-2">
            <button type="button" className={classNames("-m-2 inline-flex items-center justify-center rounded-md p-2 text-zinc-400")} onClick={() => toggleMenu(false)}>
              <span className="sr-only">Close menu</span>
              <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="MenuMobile.tsx" />
            </button>
          </div>

          {/* Links */}
          <div className="mt-2" role="navigation">
            {navigationMain?.map((item, i) => <MenuItemMobile item={item} key={`menu-mobile-${i}`} setOpen={toggleMenu} />)}
          </div>
        </div>
        <div className="w-full h-full" onClick={() => toggleMenu(false)} />
      </div>
    </div>;
};