import { BasketIcon } from "../ui/icons";
export const ShoppingCartLoaderClient = () => {
  return <button className="relative flex flex-row items-center justify-center h-full sm:mr-1 md:mr-4 sm:gap-3 aspect-square" data-sentry-component="ShoppingCartLoaderClient" data-sentry-source-file="Cart.tsx">
      <div className="md:pl-1 md:pr-3 sm:static md:border-r md:border-r-white md:text-sm">
        <BasketIcon data-sentry-element="BasketIcon" data-sentry-source-file="Cart.tsx" />
      </div>
      <span className="absolute flex items-center justify-center w-5 h-5 text-sm rounded-full right-2 bg-rose-400 md:hidden md:invisible">
        0
      </span>
      <span className="invisible hidden md:block md:visible md:text-sm">0</span>
    </button>;
};