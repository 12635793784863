"use client";

import { classNames } from "@local/utils/src/classNames";
import Link from "next/link";
import { useState } from "react";
import styles from "./styles.module.css";
import type { Payload } from "@local/payload-client/src/types";
import Image from "next/image";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
interface MenuItemProps {
  item: Payload.Mini["navigationMain"][number];
  id?: string;
}
export const MenuItemDesktopClient = ({
  item,
  id
}: MenuItemProps) => {
  const [open, setOpen] = useState(false);
  if (item.subitems && item.subitems?.length === 0) {
    return <a href={item.url ? item.url : `/category/${item.category?.slug}`}>
        {item.name}
      </a>;
  }
  if (!item.url && !item.category) {
    return null;
  }
  return <div className="h-full" onMouseLeave={() => setOpen(false)} data-sentry-component="MenuItemDesktopClient" data-sentry-source-file="index.tsx">
      <div className="relative h-full">
        <Link id={id} href={item.url ? item.url : `/category/${item.category?.slug}`} onClick={() => setOpen(!open)} onMouseEnter={() => setOpen(true)} prefetch={false} className={classNames(styles.MenuHoverItem, "flex items-center h-full")} data-sentry-element="Link" data-sentry-source-file="index.tsx">
          {item.name}
        </Link>
      </div>

      {open && <div className="absolute inset-x-0 z-[240] top-full text-zinc-500 text-sm">
          {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
          <div className="absolute inset-0 bg-white shadow-xl top-1/2" aria-hidden="true" />

          <div className="absolute bottom-0 z-50 w-full h-10 bg-gradient-to-t from-white to-transparent" />

          <div className="relative overflow-scroll bg-white max-h-[600px] pb-10">
            <div className="px-4 mx-auto max-w-7xl">
              <div className="grid items-start grid-cols-1 py-4 gap-x-4 gap-y-1">
                <div className="grid gap-x-8 gap-y-1 md:grid-cols-2 lg:grid-cols-4">
                  {item.subitems?.map((subitem, i) => <div key={`item-${i}`}>
                      {subitem.category || subitem.url ? <Link className="font-semibold tracking-wider uppercase" onClick={() => setOpen(false)} href={subitem.url ? subitem.url : `/category/${subitem.category?.slug}`} prefetch={false}>
                          {subitem.name}
                        </Link> : <span className="font-semibold tracking-wider uppercase">
                          {subitem.name}
                        </span>}
                      {subitem.image && <Link href={subitem.url ? subitem.url : `/category/${subitem.category?.slug}`} prefetch={false} onClick={() => setOpen(false)} className="block my-2 overflow-hidden" style={{
                  aspectRatio: "2 / 1"
                }}>
                          <Image alt={subitem.name} src={subitem.image?.url} width={subitem.image?.width} height={subitem.image?.height} className="hover:opacity-75" loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />
                        </Link>}
                      <ul role="list" aria-labelledby="desktop-brand-heading">
                        {subitem.subitems?.map((subsubitem, j) => <li key={`item-${i}-subitem-${j}`} className="flex">
                            <Link className="py-1 font-medium" href={subsubitem.url as string} prefetch={false} onClick={() => setOpen(false)}>
                              {subsubitem.name}
                            </Link>
                          </li>)}
                      </ul>
                    </div>)}
                </div>
              </div>
            </div>
          </div>
        </div>}
    </div>;
};