import { useId } from "react";
import styles from "./style.module.css";
export const SearchResultPlaceholder = () => {
  const id = useId();
  return <div className={styles.searchPlaceholder} data-sentry-component="SearchResultPlaceholder" data-sentry-source-file="Placeholder.tsx">
      <div className="w-2/5 mb-10 h-[2.2em]" />
      <ul className="grid grid-cols-2 gap-6 mb-10 md:grid-cols-4">
        {Array.from(Array(4)).map((_, i) => <li key={`placeholder-shimmer-${id}-${i}`}>
            <div className="w-full aspect-square" />
            <div className="w-2/3 mt-5 h-[1.8em]" />
          </li>)}
      </ul>
    </div>;
};