"use client";

import useScroll from "@/utils/useScroll";
import { useEffect, type FC } from "react";
interface ScrollHandlerProps {
  className: string;
  elementRef: React.RefObject<HTMLDivElement>;
  promoBannerHeigth?: number;
}
const ScrollHandler: FC<ScrollHandlerProps> = ({
  elementRef,
  className,
  promoBannerHeigth
}) => {
  const scrollPosition = useScroll();
  const height = promoBannerHeigth ?? 0;
  useEffect(() => {
    if (scrollPosition.y > height) {
      elementRef.current?.classList.add(className);
    } else {
      elementRef.current?.classList.remove(className);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition]);
  return null;
};
export default ScrollHandler;