"use client";

import { useEffect, useRef, useState } from "react";
import { useDocumentVisible } from "@local/utils/src/useDocumentVisible";
import { useCart } from "@local/cart/src/useCart";
const DynamicFaviconAndTitleClient = ({
  favicon
}: {
  favicon?: string;
}) => {
  const isTabVisible = useDocumentVisible();
  const faviconSize = 32;
  const [result, setResult] = useState<string | null>(null);
  const {
    getCount
  } = useCart();

  // FAVICON
  const draw = () => {
    const items = getCount() > 0 ? getCount() : 1;
    if (typeof document === "undefined") {
      return favicon;
    }
    const canvas = document.createElement("canvas");
    canvas.setAttribute("width", `${faviconSize}`);
    canvas.setAttribute("height", `${faviconSize}`);
    const ctx = canvas.getContext("2d")!;
    const img = document.createElement("img");
    img.setAttribute("crossorigin", "anonymous");
    if (favicon) {
      img.setAttribute("src", favicon);
    }
    img.onload = () => {
      ctx.drawImage(img, 0, 0, faviconSize, faviconSize);
      // Draw Notification Circle
      ctx.beginPath();
      ctx.arc(canvas.width - faviconSize / 3.6, canvas.height - faviconSize / 3.6, faviconSize / 3.6, 0, 2 * Math.PI);
      ctx.fillStyle = "#FF0000";
      ctx.fill();
      ctx.font = `bold ${faviconSize / 1.8}px arial`;
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.fillStyle = "white";
      ctx.textBaseline = "bottom";
      ctx.textAlign = "right";
      ctx.fillText(items.toString(), faviconSize - 6 / 2, faviconSize + 1,
      // This will prevent the text from going outside the favicon, instead it'll squeeze his with to fit in
      faviconSize);
      setResult(canvas.toDataURL("image/png"));
    };
    result && canvas && canvas.remove();
  };
  const generate = (url: string) => {
    const linkElements = [];
    const linkEl = document.createElement("link");
    linkEl.type = "image/x-icon";
    linkEl.rel = "icon";
    linkEl.href = url;
    const linkApple = document.createElement("link");
    linkApple.rel = "apple-touch-icon";
    linkApple.href = url;
    linkElements.push(linkEl, linkApple);
    return linkElements;
  };
  const replace = (url: string) => {
    if (document) {
      const head = document.getElementsByTagName("head")[0];
      const links = head.getElementsByTagName("link");
      for (var i = links.length; --i >= 0;) {
        if (links[i].getAttribute("rel")?.length && /\bicon\b/i.test(links[i].getAttribute("rel")!)) {
          links[i].setAttribute("href", url);
        }
      }
    }
  };
  useEffect(() => {
    if (typeof document === "undefined" || !favicon) {
      return;
    }
    draw();
    if (isTabVisible) {
      replace(favicon);
    } else {
      replace(result ?? favicon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favicon, isTabVisible]);
  return <></>;
};
export default DynamicFaviconAndTitleClient;