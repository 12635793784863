import type { Payload } from "@local/payload-client/src/types";
import { classNames } from "@local/utils/src/classNames";
import Image from "next/image";
import Link from "next/link";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
interface Props {
  image?: Payload.Media | null;
  storeName: string;
  className?: string;
  noHeaderElement?: boolean;
}
export const LogoServer = ({
  image,
  storeName,
  className,
  noHeaderElement
}: Props) => <div className={classNames(className ?? "", "flex-row flex justify-center align-middle py-1 items-center self-center")} data-sentry-component="LogoServer" data-sentry-source-file="Logo.tsx">
    <Link href="/" className="w-full h-full" prefetch={false} data-sentry-element="Link" data-sentry-source-file="Logo.tsx">
      {/* Conditionally render either a H1 or DIV based on noHeaderElement with purpose of single H1 element on page*/}
      {noHeaderElement ? <div className="h-full">
          {image ? <Image width={image.width > 260 ? "245" : image.width} height={image.height} src={image.url} alt={`Return to ${storeName} homepage`} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} /> : <span className="text-2xl">{storeName}</span>}
        </div> : <h1 className="h-full">
          {image ? <Image width={image.width > 260 ? "245" : image.width} height={image.height} src={image.url} alt={`Return to ${storeName} homepage`} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} /> : <span className="text-2xl">{storeName}</span>}
        </h1>}
    </Link>
  </div>;
export const LogoNoLinkServer = ({
  image,
  storeName,
  className,
  noHeaderElement
}: Props) => <div className={classNames(className ?? "", "flex-row flex justify-center align-middle py-1 items-center self-center")} data-sentry-component="LogoNoLinkServer" data-sentry-source-file="Logo.tsx">
    {noHeaderElement ? <div className="h-full">
        {image ? <Image width={image.width > 260 ? "245" : image.width} height={image.height} src={image.url} alt={`Return to ${storeName} homepage`} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} /> : <span className="text-2xl">{storeName}</span>}
      </div> : <h1 className="h-full">
        {image ? <Image width={image.width > 260 ? "245" : image.width} height={image.height} src={image.url} alt={`Return to ${storeName} homepage`} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} /> : <span className="text-2xl">{storeName}</span>}
      </h1>}
  </div>;