import { create } from "zustand";
interface MenuState {
  isOpen: boolean;
  isAnimating: boolean;
  toggleOpen: (target?: boolean) => void;
}
export const useMenuLayout = create<MenuState>(set => ({
  isOpen: false,
  isAnimating: false,
  toggleOpen: target => {
    set(() => ({
      isAnimating: true
    }));
    const timeout_f = setTimeout(() => {
      set(state => ({
        isOpen: typeof target !== "undefined" ? target : !state.isOpen
      }));
      clearTimeout(timeout_f);
    }, 1);
    const timeout_s = setTimeout(() => {
      set(() => ({
        isAnimating: false
      }));
      clearTimeout(timeout_s);
    }, 501);
  }
}));