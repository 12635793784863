"use client";

import { FaqItemBlockClient } from "@/components/Faq/FaqItemBlock";
import Image from "@/components/Image";
import { Price, useI10n } from "@local/i10n";
import type { Hit } from "instantsearch.js";
import Link from "next/link";
import { useMemo } from "react";
import { useSearchLayout } from "./useSearchLayout";
export const FaqResults = ({
  faqs,
  sendEvent
}: {
  faqs: Hit[];
  sendEvent: any;
}) => {
  return <div className="mb-10" data-sentry-component="FaqResults" data-sentry-source-file="ResultComponents.tsx">
      {faqs.map(faq => {
      return <div key={faq?.id} onClick={() => sendEvent("click", faq, "Hit Clicked")} onAuxClick={() => sendEvent("click", faq, "Hit Clicked")}>
            <FaqItemBlockClient data={{
          question: faq?.title,
          answer: faq?.content,
          id: faq?.id,
          link: faq?.link
        }} isSearch={true} />
          </div>;
    })}
    </div>;
};
export const CardResults = ({
  cards,
  sendEvent,
  type
}: {
  cards: Hit[];
  sendEvent: any;
  type?: string;
}) => {
  const {
    country
  } = useI10n();
  const {
    toggleOpen
  } = useSearchLayout();
  const cardsWithPrices = useMemo(() => {
    const data: Array<Hit & {
      prices?: {
        price: number;
        origPrice: number;
      } | null;
    }> = [];
    cards.forEach(card => {
      data.push({
        ...card,
        prices: type === "products" || type === "bundles" ? getPrices(card, country) : null
      });
    });
    return data;
  }, [cards, country, type]);
  const onClick = (card: (typeof cardsWithPrices)[0]) => {
    if (document) {
      document.body.style.overflow = "auto";
      toggleOpen(false);
    }
    sendEvent("click", card, "Hit Clicked");
  };
  return <ul className="grid grid-cols-2 gap-6 mb-10 md:grid-cols-4" data-sentry-component="CardResults" data-sentry-source-file="ResultComponents.tsx">
      {cardsWithPrices.map(card => <li key={card?.id} onClick={() => onClick(card)} onAuxClick={() => onClick(card)}>
          <Link href={`/${type === "categories" ? "category" : "product"}/${card?.slug}`} prefetch={false}>
            <Image className="w-full mb-4 rounded-md aspect-square" src={card?.image} alt={`Clickable Image of ${card?.name}`} />
            <div className="w-full text-center">
              <div className="mb-1">
                <b>{card?.name}</b>
              </div>
              {card.prices && <>
                  {card.prices.price ? <>
                      <div className="flex justify-evenly" tabIndex={0}>
                        <span className="line-through text-neutral-600" aria-label="Previous price">
                          <Price value={card.prices.origPrice * 100} />
                        </span>
                        <span>
                          <b>
                            <Price value={card.prices.price * 100} />
                          </b>
                        </span>
                      </div>
                      <div className="text-red-600 font-xs">
                        Save{" "}
                        {100 - Math.round(100 * card.prices.price / card.prices.origPrice / 5) * 5}
                        %
                      </div>
                    </> : <Price value={card.prices.origPrice * 100} />}
                </>}
            </div>
          </Link>
        </li>)}
    </ul>;
};
const getPrices = (hit: Hit, country: string) => {
  switch (country) {
    case "us":
      return {
        price: hit?.priceUsd,
        origPrice: hit?.origPriceUsd
      };
    case "ca":
      return {
        price: hit?.priceCad,
        origPrice: hit?.origPriceCad
      };
    case "au":
      return {
        price: hit?.priceAud,
        origPrice: hit?.origPriceAud
      };
    case "nz":
      return {
        price: hit?.priceNzd,
        origPrice: hit?.origPriceNzd
      };
    case "gb":
      return {
        price: hit?.priceGbp,
        origPrice: hit?.origPriceGbp
      };
    case "eu":
      return {
        price: hit?.priceEur,
        origPrice: hit?.origPriceEur
      };
    case "ch":
      return {
        price: hit?.priceChf,
        origPrice: hit?.origPriceChf
      };
    default:
      return {
        price: hit?.priceUsd,
        origPrice: hit?.origPriceUsd
      };
  }
};